<script>
	import { onMount } from 'svelte'
	import { LayoutTheme } from 'falcon'
	import { system } from '@/service/store/system.js'
	export let asr = {}
	onMount(() => {
		document.body.style.setProperty('--falcon-body-bg', '#004f6d')
	})
</script>

<LayoutTheme {system}>
	<div class="container-fluid">
		<div class="content pb-5">
			<div class="row justify-content-center">
				<div class="col-12 col-md-9 col-lg-6">
					<nav class="navbar navbar-light navbar-top navbar-expand">
						<div class="card" style="width: 100%">
							<div class="card-body text-center py-3">
								<img
									height="100"
									src="/asset/ontrack-icon.avif"
									alt="Logo of Mr. Car Shipper"
								>
							</div>
						</div>
					</nav>
					<uiView />
				</div>
			</div>
		</div>
	</div>
</LayoutTheme>
