<script>
	import { showAutoQuote, showSignableAdmin } from '@/service/store/current-user.js'
	export let asr
	export let user
</script>

<h1>System</h1>

<div class="row">
	<div class="col-xs-12 col-md-9 col-xl-6">
		<ul class="list-group">
			{#if $showAutoQuote}
				<li class="list-group-item d-flex justify-content-between align-items-start">
					<div class="ms-2 me-auto">
						<div class="fw-bold">
							<a href={asr.makePath('app.systemSettings.autoQuoteCampaigns')}>Auto-Quote Campaigns</a>
						</div>
						Manage rates and configurations for automated quotes.
					</div>
				</li>
			{/if}
			{#if $showSignableAdmin}
				<li class="list-group-item d-flex justify-content-between align-items-start">
					<div class="ms-2 me-auto">
						<div class="fw-bold">
							<a href={asr.makePath('app.systemSettings.signableTemplates')}>Signable Templates</a>
						</div>
						Manage templates for signable documents, sent to customers and carriers.
					</div>
				</li>
			{/if}
			<li class="list-group-item d-flex justify-content-between align-items-start">
				<div class="ms-2 me-auto">
					<div class="fw-bold">
						<a href={asr.makePath('app.systemSettings.autoQuoteDocumentation')}>Auto-Quote API Documentation</a>
					</div>
					Information about integrating with the OnTrack Auto-Quote API.
				</div>
			</li>
			<li class="list-group-item d-flex justify-content-between align-items-start">
				<div class="ms-2 me-auto">
					<div class="fw-bold">
						<a href={asr.makePath('app.systemSettings.centralDispatch')}>Central Dispatch</a>
					</div>
					Integration and connection settings.
				</div>
			</li>
			{#if user?.attributes?.permissions?.manageApp}
				<li class="list-group-item d-flex justify-content-between align-items-start">
					<div class="ms-2 me-auto">
						<div class="fw-bold">
							<a href={asr.makePath('app.systemSettings.databaseEditor')}>Database Editor</a>
						</div>
						Edit the raw data of any resource. <em>Very dangerous.</em>
					</div>
				</li>
			{/if}
			<li class="list-group-item d-flex justify-content-between align-items-start">
				<div class="ms-2 me-auto">
					<div class="fw-bold">
						<a href={asr.makePath('app.systemSettings.emailTemplates')}>Email Templates</a>
					</div>
					Templates for emails sent to customers.
				</div>
			</li>
			<li class="list-group-item d-flex justify-content-between align-items-start">
				<div class="ms-2 me-auto">
					<div class="fw-bold">
						<a href={asr.makePath('app.systemSettings.jobs')}>Job Inspector</a>
					</div>
					Check on long running jobs like report generation and CD updates.
				</div>
			</li>
			<li class="list-group-item d-flex justify-content-between align-items-start">
				<div class="ms-2 me-auto">
					<div class="fw-bold">
						<a href={asr.makePath('app.systemSettings.leadAssignment')}>Lead Assignment</a>
					</div>
					Configure ratio of leads assigned to sales users.
				</div>
			</li>
			<li class="list-group-item d-flex justify-content-between align-items-start">
				<div class="ms-2 me-auto">
					<div class="fw-bold">Terms &amp; Conditions</div>
					Order, dispatch, carrier payment, and so on.
				</div>
			</li>
		</ul>
	</div>
</div>
