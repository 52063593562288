import { opportunityColumns } from '../value/report-columns.js'
import { opportunityFilterableDateTypes } from '../value/opportunity-filterable-date-types.js'
import { opportunityGroupings } from '../value/opportunity-groupings.js'
import { assignmentStatus, cancellationFilter, carrierStatus, dateRangeSelector, heldFilter, referrerRelationship } from '../value/report-filters.js'
import { opportunityFilterableDateExists } from '../value/opportunity-filterable-date-exists.js'
import { jsonApiRelationshipSingular } from '../util/model-builder-helpers.js'
import { opportunityStatus } from '../value/opportunity-status.js'

export const $selfFragment = {
	user: false,
}

export const type = 'custom-report'

export default {
	description: 'A custom report.',
	type: 'object',
	additionalProperties: false,
	properties: {
		id: {
			description: 'Globally unique identifier for the report.',
			type: 'string',
		},
		type: {
			type: 'string',
			enum: [ type ],
		},
		meta: {
			$ref: '#/components/schemas/meta',
		},
		attributes: {
			type: 'object',
			additionalProperties: false,
			required: [
				'name',
			],
			properties: {
				type: {
					description: 'The report resource root type property.',
					type: 'string',
					enum: [
						'opportunity',
					],
				},
				orderedColumns: {
					type: 'array',
					items: {
						type: 'string',
						enum: Object.keys(opportunityColumns({})),
					},
				},
				assignmentStatus: {
					type: 'string',
					enum: Object.keys(assignmentStatus),
				},
				assignedIds: {
					type: 'array',
					items: {
						type: 'string',
					},
				},
				cancellationStatus: {
					type: 'string',
					enum: Object.keys(cancellationFilter),
				},
				carrierStatus: {
					type: 'string',
					enum: Object.keys(carrierStatus),
				},
				carrierIds: {
					type: 'array',
					items: {
						type: 'string',
					},
				},
				datesExist: {
					type: 'array',
					items: {
						type: 'string',
						enum: Object.keys(opportunityFilterableDateExists),
					},
				},
				dateRanges: {
					type: 'array',
					items: {
						type: 'string',
						enum: Object.keys(dateRangeSelector),
					},
				},
				about: {
					type: 'string',
					description: 'The user friendly description of the report.',
				},
				grouping: {
					type: 'string',
					enum: Object.keys(opportunityGroupings),
					description: 'Grouping of found records',
				},
				heldStatus: {
					type: 'string',
					enum: Object.keys(heldFilter),
				},
				maxDateType: {
					type: 'string',
					enum: Object.keys(opportunityFilterableDateTypes),
					description: 'Date property to use for the maximum date.',
				},
				minDateType: {
					type: 'string',
					enum: Object.keys(opportunityFilterableDateTypes),
					description: 'Date property to use for the minimum date.',
				},
				name: {
					type: 'string',
					description: 'The user friendly name of the report.',
				},
				opportunityStatuses: {
					type: 'array',
					items: {
						type: 'string',
						enum: Object.keys(opportunityStatus),
					},
				},
				referrerStatus: {
					type: 'string',
					enum: Object.keys(referrerRelationship),
				},
				referrerIds: {
					type: 'array',
					items: {
						type: 'string',
					},
				},
				referrerTags: {
					type: 'array',
					items: {
						type: 'string',
					},
				},
			},
		},
		relationships: {
			type: 'object',
			additionalProperties: false,
			properties: {
				user: {
					description: 'User who manages this custom report.',
					...jsonApiRelationshipSingular('user'),
				},
			},
		},
	},
}
