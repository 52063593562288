<script>
	import { load } from 'jsonapi-svelte-form/mapper'
	import { clone } from '@/shared/util/clone.js'
	import DateString from '@/component/atom/DateString.svelte'
	import NamedIcon from '@/component/atom/NamedIcon.svelte'
	import TimelineItemNote from './TimelineItemNote.svelte'
	import TimelineItemEmail from './TimelineItemEmail.svelte'
	import HistoryChangesColumns from '@/component/table/history/ChangeRow.svelte'
	import { emailTemplateIdToName } from '@/shared/value/email-template-id-to-name.js'
	import { jobPaths } from '@/shared/value/job-paths.js'
	import MaybeLink from '@/component/atom/MaybeLink.svelte'

	export let item
	export let opportunityId
	export let asr
	export let api
	export let includedById
	export let usersById
	export let resourceHighlightId

	$: userId = item.relationships?.sendingUser?.data?.id
		|| item.attributes?.userId
		|| item.relationships?.creator?.data?.id
		|| item.relationships?.user?.data?.id
	$: assignedUserId = item.relationships?.assigned?.data?.id
	$: completerUserId = item.relationships?.completer?.data?.id
	$: updaterUserId = item.relationships?.updater?.data?.id
	$: emailTemplateId = item.relationships?.template?.data?.id || '_'
	$: completedDate = item.attributes?.completed

	$: jobPath = item.attributes?.parameters?.jobPath
	$: jobBadStatus = [ 'FAILED', 'BLOCKED' ].includes(item.attributes?.status)

	let hideRow
</script>

<style>
	.not-set {
		color: #b7bed2;
	}
</style>

{#if !hideRow}
<tr class:table-success={item.id === resourceHighlightId}>
	<td class="col-date">
		{#if item.type === 'note'}
			<a href={asr.makePath('app.notes.edit.noteId', { noteId: item.id }, { inherit: null })}>
				<DateString time multiline date={item?.meta?.created} />
			</a>
		{:else if item.type === 'sent-email'}
			<a href={asr.makePath('app.systemSettings.sentEmail.sentEmailId', { sentEmailId: item.id }, { inherit: false })}>
				<DateString time multiline date={item.meta.created} />
			</a>
		{:else}
			<DateString time multiline date={item?.meta?.created} />
		{/if}
	</td>
	<td class="col-user">
		{#if userId}
			<p class="mt-0 mb-1">
				<a href={asr.makePath('app.users.edit.userId', { userId }, { inherit: null })}>
					{usersById[userId]?.attributes?.name || '-'}
				</a>
			</p>
		{/if}
		{#if item.type === 'sent-email'}
			<TimelineItemEmail label="Sent As" items={[ item.attributes.fromAddress ]} />
		{/if}
	</td>
	<td class="col-action">
		{#if item.type === 'sent-email'}
			<NamedIcon name="email" />
			{#if includedById[emailTemplateId]?.attributes?.useForAutoQuote}
				<NamedIcon name="autoQuote" />
				Auto-Quote
			{/if}
			Email:
			{#if emailTemplateIdToName[emailTemplateId] || includedById[item.relationships.template?.data?.id]}
				<a href={asr.makePath('app.systemSettings.emailTemplates.edit.emailTemplateId', { emailTemplateId }, { inherit: null })}>
					{emailTemplateIdToName[emailTemplateId] || includedById[item.relationships.template.data.id]?.attributes?.name || 'Unknown Template'}
				</a>
			{:else}
				System Email
			{/if}
			{#if item.relationships.signableDocument}
				<br>
				<NamedIcon name="signableDocument" />
				<MaybeLink href={asr.makePath('app.signableDocuments.signableDocumentId', { signableDocumentId: item.relationships.signableDocument.data.id }, { inherit: null })}>
					{includedById[item.relationships.signableDocument.data.id]?.attributes?.name || 'Signable Document'}
				</MaybeLink>
			{/if}
			<TimelineItemEmail label="To" items={[ item.attributes.toAddress ]} />
			{#if item.attributes?.ccRecipients}
				<TimelineItemEmail
					label="CC"
					items={item.attributes.ccRecipients.split(',').map(c => c.trim())}
				/>
			{/if}
		{:else if item.type === 'history'}
			<NamedIcon name="history" />
			Change
		{:else if item.type === 'note'}
			<NamedIcon name="comment" />
			Note
		{:else if item.type === 'job'}
			<strong class:text-muted={!jobBadStatus} class:text-danger={jobBadStatus}>
				{jobPath}
			</strong>
			<br>
			<span class="text-muted"><span class="fa fa-info-circle"></span> {jobPaths[jobPath]}</span>
		{:else if item.type === 'signable-document'}
			<NamedIcon name="signableDocument" />
			Customer signed
			<a href={asr.makePath('app.signableDocuments.signableDocumentId', { signableDocumentId: item.id }, { inherit: false })}>
				{item.attributes.name}
			</a>
			<br>
			<em>
				"{item.attributes.signerName}"
				({item.attributes.signerEmailAddress})
			</em>
		{/if}
	</td>
	<td>
		{#if item.type === 'sent-email'}
			{#if item.attributes?.parameters?.note}
				{@html item.attributes?.parameters?.note}
			{/if}
		{:else if item.type === 'history'}
			<div class="row">
				<div class="col-xs-12 col-lg-3">
					{item.attributes?.note}
				</div>
				<div class="col-xs-12 col-lg-9">
					<table class="table table-sm">
						<tbody>
							{#each (item.attributes?.changes || []) as change}
								<tr>
									<HistoryChangesColumns {includedById} {change} parentType="opportunity" />
								</tr>
							{/each}
						</tbody>
					</table>
				</div>
			</div>
		{:else if item.type === 'note'}
			<TimelineItemNote
				{asr}
				{api}
				{opportunityId}
				{usersById}
				{includedById}
				form={load({ data: clone(item), included: Object.values(includedById || {}) }, 0)}
				on:visibilityChanged={() => { hideRow = true }}
			/>
		{:else if item.type === 'job' && item.attributes?.executions?.length}
			{#if jobBadStatus}
				{#each item.attributes.executions as ex}
					{#each (ex.errors || []) as e}
						<span class="text-danger">{e.detail}</span>
						<br>
						<strong class="text-muted">{e.status} {e.code}: {e.title}</strong>
					{/each}
				{/each}
			{/if}
		{/if}
	</td>
</tr>
{/if}
