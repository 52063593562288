<script>
	import { markdownToHtml } from '@/shared/util/markdown-to-html.js'

	export let schema = { type: 'unknown' }
</script>

<style>
	dd { margin-left: 1em; }
</style>

<div class="card mb-3">
	<div class="card-body pb-0">
		<p class="mb-0">Type: <code>{schema.type}</code></p>
		{#if schema.description}
			<p class="mb-0">{@html markdownToHtml(schema.description)}</p>
		{/if}
		{#if schema.additionalProperties !== undefined}
			<p class="mb-0">Additional Properties: <code>{schema.additionalProperties}</code></p>
		{/if}
		{#if schema.type === 'array'}
			<p class="mb-0">Items:</p>
			{#if schema.items}
				<svelte:self schema={schema.items} />
			{:else}
				<div class="alert alert-danger">Schema definition missing for: <code>items</code></div>
			{/if}
		{:else if schema.type === 'object'}
			<dl>
				{#each Object.keys(schema.properties || {}) as key}
					<dt>
						<code>{key}</code>
						{#if schema.required?.includes(key)}
							<span>Required</span>
						{/if}
					</dt>
					<dd>
						{#if schema.properties[key].type === 'array'}
							<svelte:self schema={schema.properties[key].items} />
						{:else if schema.properties[key].type === 'object'}
							<svelte:self schema={schema.properties[key]} />
						{:else}
							<p class="mb-0">Type: <code>{schema.properties[key].type}</code></p>
							{#if schema.properties[key].description}
								<p class="mb-0">{@html snarkdown(schema.properties[key].description)}</p>
							{/if}
							{#if schema.properties[key].enum}
								<p class="mb-0">
									Allowed values:
									{#each schema.properties[key].enum as string}
										<code>{string}</code>
									{/each}
								</p>
							{/if}
						{/if}
					</dd>
				{/each}
			</dl>
		{/if}
	</div>
</div>
